.radioOption {
    width : 1.3vw;
    height : 1.3vw;
    border : none;
    background-color : #A3B1C6;
    text-align : center;
}

.gedrag {
    margin : auto;
    width : 45vw;
    height : 45vh;
    border-radius: 25px;
    box-shadow: 9px 9px 16px rgba(211, 214, 216, 0.6), -9px -9px 16px    rgba(255,255,255, 0.5)
}

.radioContainer {
    margin : 2%;
    text-align : center;
}

.desirabilityImg {
    display : table-cell;
    vertical-align : middle;
    max-height : 80%;
    margin : auto;
    object-fit : contain;
}

.optionText {
    font-size: 1vw;
    text-align: center;
    color: #393E46;
    opacity: 1;
}

.desirabilityPanel {
    box-shadow:  9px 9px 16px rgba(163,177,198, 0.6) inset, -9px -9px 16px    rgba(255,255,255, 0.5) inset;
    height : 21vw;
    border: none;
    border-radius: 51px;
    background : rgb(235, 238, 241);
    text-align : center;
}

.autoMargin {
    margin-right : auto !important;
    margin-left : auto !important;
    width : 65vw;
}


@media only screen and (max-width: 770px) {
    .radioOption {
        width: 5vw;
        height: 5vw;
        text-align : left;
        margin-right: 10px;
    }

    .gedrag {
        margin : auto;
        width : 100%;
        height : auto;
        border-radius: 25px;
        box-shadow: 9px 9px 16px rgba(211, 214, 216, 0.6), -9px -9px 16px    rgba(255,255,255, 0.5)
    }

    .optionText {
        font-size :  1.2em;
        text-align: left;
    }

    .desirabilityPanel {
        border-radius: 21px;
        width : 100%;
        height : 20vh;
        object-fit : contain;
    }

    .radioContainer {
        margin : 0%;
        text-align : left;
    }

    .desirabilityImg {
        padding: 10%;
    }
    
}
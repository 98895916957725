body {
    margin-right: 10px;
    margin-left : 10px;
    padding-right : 25px;
    padding-left : 25px;
    background: #EBEEF1 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: left;
}

h2, .header {
    text-align: left;
    font-size: 8vmin;
    font-weight: bold;
    letter-spacing: 0px;
    color: #393E46;
    opacity: 1;
}

.scrollable {
    overflow-x: hidden;
    overflow-y : auto;
    width : 100%;
    height : 65vh;
}

p, label {
    text-align: left;
    font-size: 2.5vmin;
    letter-spacing: 0rem;
    color: #393E46;
    opacity: 1;
}

#start {
    width : 350px;
    height: 72px;
}

.userdata {
    width: 60%;
}

.footerLogo {
    height : 1.5vw;
}

.widthControl {
    width : 80%;
}

.heightControl {
    height : 55vh;
}

.rowCenter {
    text-align : center;
    margin-right : 5% !important;
    margin-left : 5% !important;
}

.imageContainer {
    width : 80%;
    height : 70vh;
    margin-right : auto;
    margin-left : auto;
    display : block;
    text-align: center;
    object-fit : contain;
}

@media (max-width:1200px) {
    .adjustButton .fixedButtons {
        position : relative;
        bottom : 5px;
    }

    .heightControl {
        height : 55vh;
    }

     .imageContainer {
        height : 100%;
    }
}


button {
    background: #EBEEF1 0% 0% no-repeat padding-box;
    width: 14vw;
    height: 4vw;
    border-radius: 51px;
    opacity: 1;
    border: none;
    box-shadow: 9px 9px 16px rgba(163,177,198, 0.6), -9px -9px 16px    rgba(255,255,255, 0.5);
    text-align: center;
    line-height: 36px;
    font-weight: 300;
    font-variant: normal;
    font-style: normal;
    font-size: 1.6vw;
    font-family: Helvetica, Neue;
    letter-spacing: 0px;
    color: #0558FC;
}

*:focus {
    outline : 0 !important;
}


@media only screen and (max-width: 600px) {

    h2, .header {
        font-size: 11vw;
    }
    button {
        width: 37vw;
        height: 11vw;
        font-size: 4.5vw;
        line-height: 26px;
    }

    .userdata {
        width: 100%
    }
    #start {
        width: 100%;
        height: 50.3px;
        font-size: 100%;
        line-height: 26px;
    }

    p {
        font-size: 1.2em;
    }

    .scrollable {
        height : auto;
    }

    label {
        font-size : 1em;
    }

    .fixedButtons {
        position : relative;
        bottom : 5px;
    }

    .widthControl {
        width : 100%;
    }

    .footerLogo {
        height : 6vw;
    }

    body {
        margin : 5px;
        padding : 0;
    }

    .heightControl {
        height : auto;
    }

    .imageContainer {
        height : 100%;
    }

}


.containerStyle {
    background-color :  #EBEEF1;
    border: none;
    box-shadow: 9px 9px 16px rgba(163,177,198, 0.3) inset, -9px -9px 16px    rgba(255,255,255, 0.5) inset;
    border-radius: 25px;
    padding : 40px;
}
.containerStyle::-webkit-scrollbar {
    width: 10px;
}

.containerStyle::-webkit-scrollbar-track {
    border : none;
    appearance: none;
    background-color: #EBEEF1;
}

.containerStyle::-webkit-scrollbar-thumb {
    background-color: #A3B1C6;
    outline: 1px solid #A3B1C6;
}


@media (min-width:1200px) {
    .fixedButtons {
        position: absolute;
        top: calc(100% - 30vh);
    }
}

@media (max-width:1500px) {
    .never .fixedButtons {
        position : relative;
        top : 10px;
    }
}









:root {
  font-size : 16px;
}

.sliderContainer {
  width : 93%;
}
.ticks {
  display: flex;
  justify-content: space-between;
  padding: 18px 10px;
}

.sliderText {
  font-size: 0.9rem;
  color : #393E46;
}

.tick {
  position: relative;
  display: flex;
  justify-content: center;
  width: 1px;
  background:  #393E46;
  height: 0.5rem;
  line-height: 3.125rem ;
  margin-bottom: 0.55rem;
}

.tickEdge {
  position: relative;
  display: flex;
  justify-content: center;
  width: 1px;
  background: #393E46;
  height: 0.9375rem;
  line-height: 3.125rem ;
  margin-bottom: 0.25rem;
}

.range {
  width : 90%;
}


.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 1.375rem;
  background: #ECF0F3 0% 0% no-repeat padding-box;
  border-radius: 51px;
  outline: none;
  opacity: 0.7;
  border: none;
  box-shadow: 6px 6px 8px rgba(163,177,198, 0.6) inset, -6px -6px 8px    rgba(255,255,255, 0.5) inset;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.hum .slider {
  height: 2.5vw;
}

.hum .slider::-webkit-slider-thumb {
  width: 1.1vw;
  height: 6.4vw;
}


.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1.1vw;
  height: 4.6vw;
  border-radius: 51px;
  background:  #ECF0F3;
  cursor: pointer;
  box-shadow: 9px 9px 16px rgba(163,177,198, 0.6), -9px -9px 16px    rgba(255,255,255, 0.5);
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .slider::-webkit-slider-thumb {
    width: 3vw;
    height: 12vw;
  }

  .hum  .slider::-webkit-slider-thumb {
    width: 5vw;
    height: 16vw;
  }

  .slider {
    height: 20px;
  }

  .hum .slider {
    height: 30px;
  }

  .sliderContainer {
    width : 100%;
  }

  .range {
    width : 100% !important;
  }
}




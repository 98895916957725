.questionHeader{
    text-align : left;
    font-size : 2.3vmin;
    color: #393E46;
}

@media only screen and (max-width: 600px) {
    .questionHeader {
        text-align: center;
        font-size: 1em;
    }
}